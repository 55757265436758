import React, { useEffect, useRef, useState } from 'react';
import LineChart from '../../../../components/apex-chart/LineChart';
import LongMenu from '../../../../utils/longMenu/LongMenu';
import { generateCSV1, reportDataWithGraph } from '../../../../common-function/CommonFunction';

const Group4 = ({ customTagData, apiData = [], devices }: any) => {
    const [value, setValue] = useState(0);
    const chartRef = useRef<HTMLDivElement>(null);

    const tags = ["tag12", "tag13", "tag14", "tag15"];

    const prepareTagData = (tag: string) => {
        return apiData.map(({ timestamp, [tag]: value }: any) => ({
            timestamp,
            value: value || 0
        }));
    };

    const prepareChartData = (tag: string) => ({
        name: customTagData?.[tag]?.sensorAlias || tag,
        color: customTagData?.[tag]?.color || "#000000", // Default color
        data: prepareTagData(tag),
        postfix: customTagData?.[tag]?.sensorUnit || "",
    });

    const tagData = tags.map(tag => prepareChartData(tag));

    const handleTabClick = (index: number) => {
        setValue(index);
    };

    useEffect(() => {
        const firstActiveIndex = tags.findIndex(tag => customTagData?.[tag]?.sensorStatus !== false);
        setValue(firstActiveIndex !== -1 ? firstActiveIndex : 0);
    }, [customTagData]);

    const renderTagButtons = () => {
        return tags.map((tag, index) => {
            const sensorStatus = customTagData?.[tag]?.sensorStatus;
            const sensorAlias = customTagData?.[tag]?.sensorAlias || tag;
            const isActive = value === index;

            return sensorStatus !== false ? (
                <li className="focus-within:z-10" key={tag}>
                    <button
                        className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-sm  transition-colors duration-300`}
                        style={isActive ? { backgroundColor: "#dadada" } : {}}
                        onClick={() => handleTabClick(index)}
                    >
                        {sensorAlias}
                    </button>
                </li>
            ) : null;
        });
    };

    const handleExportLineChart = async (exportOption: string) => {
        if (!apiData.length) return;

        const selectedTag = tags[value]; // Dynamically pick the tag based on `value`
        const sensorAlias = customTagData?.[selectedTag]?.sensorAlias || selectedTag;
        const sensorUnit = customTagData?.[selectedTag]?.sensorUnit || "";

        const filterData = apiData.map(({ timestamp, [selectedTag]: value }: any) => ({
            TIMESTAMP: timestamp,
            [sensorAlias]: value || 0,
            UNIT: sensorUnit,
        }));

        switch (exportOption) {
            case "Download as CSV":
                generateCSV1(filterData);
                break;
            case "Download as PDF":
                reportDataWithGraph(
                    chartRef,
                    filterData,
                    devices?.deviceName,
                    devices?.Customer?.name,
                    `${sensorAlias} Summary`,
                    apiData?.length > 0
                        ? apiData[0].timestamp + " - " + apiData[apiData.length - 1].timestamp
                        : ""
                );
                break;

            default:
                return;
        }
    };


    return (
        <div>
            <div className="pt-[5px]"></div>

            <div className="border border-gray-400 rounded-md p-2">
                <div className="relative">
                    <div className="absolute top-0 right-0">
                        <LongMenu
                            options={["Download as CSV", "Download as PDF"]}
                            onOptionClick={handleExportLineChart}
                            id={1}
                        />
                    </div>
                </div>
                <p>
                    Data Variation with time (between{" "}
                    {apiData.length > 0
                        ? `${apiData[apiData.length - 1].timestamp} to ${apiData[0].timestamp}`
                        : " "}
                    )
                </p>
                <div className="pb-[20px] pt-[10px]">
                    <div className="w-[250px]">
                        <ul className="text-sm font-medium text-center text-gray-500 rounded-lg sm:flex">
                            {renderTagButtons()}
                        </ul>
                    </div>
                </div>
                <div ref={chartRef}>
                    <LineChart
                        yAxisName="Data"
                        stroke={true}
                        data={apiData.length >= 0 ? [tagData[value]] as any : []} // Default to empty array if no data exists
                        xAxisName="Time"
                    />
                </div>
            </div>
        </div>
    );
};

export default Group4;
