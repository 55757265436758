import React, { useEffect, useRef, useState } from 'react'
import { http } from '../../../helper/http';
import { formatTimestamp } from '../../../common-function/CommonFunction';
import MobileTowerIcon from '../../../components/apex-chart/HeatMapChart';
import CardTwo from '../../../components/dashBoardComponents/CardTwo';
import Loader4 from '../../../components/Loader/Loader4';
import { toast } from "react-toastify";
import { Switch } from '@mui/material';
import Group1 from './components/Group1';
import Group2 from './components/Group2';
import Group3 from './components/Group3';
import Group4 from './components/Group4';
import Group5 from './components/Group5';
import Group6 from './components/Group6';
import Group7 from './components/Group7';
import { io } from "socket.io-client";
const tags = ["tag1", "tag2", "tag3", "tag4", "tag5", "tag6", "tag7", "tag8", "tag9", "tag10", "tag11", "tag12", "tag13", "tag14", "tag15", "tag16", "tag17", "tag18", "tag19", "tag20", "tag21", "tag22", "tag23", "tag24", "tag25", "tag26", "tag27"];
const group1 = ["tag1", "tag2", "tag3"]
const group2 = ["tag4", "tag5", "tag6", "tag7"]
const group3 = ["tag8", "tag9", "tag10", "tag11"]
const group4 = ["tag12", "tag13", "tag14", "tag15"]
const group5 = ["tag16", "tag17", "tag18", "tag19"]
const group6 = ["tag20", "tag21", "tag22", "tag23"]
const group7 = ["tag24", "tag25", "tag26", "tag27"]

const baseUrl = process.env.REACT_APP_BASE_URL;
const CustomTag19 = ({ devices, selectedDevice }: any) => {
  const [dashboardSts, setDashboardSts] = useState<any>({});
  const [customTagData, setCustomTagData] = useState<any>([]);
  const [loader, setLoader] = useState(false)
  const [apiData, setApiData] = useState<any[]>([]);
  const automationIORef = useRef<any>(null);
  const responseTimeoutRef: any = useRef<NodeJS.Timeout | null>(null);
  const [pumpStatus, setPumpStatus] = useState(false);
  console.log(dashboardSts, "hii")
  const fetchData = async () => {
    try {
      setLoader(true)

      const response = (await http(
        baseUrl + `/device-data-log?deviceId=${selectedDevice}&last24=last24`
      )) as any;

      const _data = response
        .map((d: any) => {
          const tags: any = {};
          for (let i = 1; i <= 27; i++) {
            tags[`tag${i}`] = d[`tag${i}`];
          }

          return {
            timestamp: formatTimestamp(d.timeStamp),
            ...tags,
            rssi: d["rssi"],
          };
        })


      setApiData(_data);
      setDashboardSts(_data.length ? _data[0] : "");
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false)

    }
  };

  const handleTagChange = async (tag: any) => {
    try {
      // console.log(dashboardSts,tag)
      const message = { [tag]: !dashboardSts ? "on" : "off" };
      try {
        setLoader(true);
        automationIORef.current.emit("automation_2-publish", message);
        responseTimeoutRef.current = setTimeout(() => {
          toast.warning("Gateway communication failed.");
          setLoader(false);
        }, 30000);
      } catch (error) {
        console.error(error);
      }
    } catch (err: any) {
      toast.error(err);
    }
  }

  const handleAutomationResponse = () => {

    if (automationIORef.current) {
      automationIORef.current.disconnect();
      automationIORef.current = null;
    }
    const IO: any = io(`${baseUrl}/automation_2?deviceId=${devices.deviceId}`);
    automationIORef.current = IO;

    IO.on("automation_2-response", (data: any) => {
      if (data) {
        clearTimeout(responseTimeoutRef.current);
        setLoader(false);
        if (data.relay_1 === "success") {
          toast.success(`Auatomation ${data.relay_1} successfully.`);
          setPumpStatus(data.relay_1 === "success" ? true : false);
          setTimeout(() => {
            fetchData();
          }, 5000); // 1000ms = 1 second

        } else if (data.relay_1 === "fail") {
          toast.error("Automation Failed. Ensure remote pairing is configured correctly or consult the administrator.")
        } else {
          toast.error("Automation failed");
        }
      }

    });

    return () => {
      if (automationIORef.current) {
        automationIORef.current.disconnect();
        automationIORef.current = null;
      }
    };
  };
  useEffect(() => {

    handleAutomationResponse();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice, devices.deviceId]);


  useEffect(() => {
    if (devices?.DeviceTemplate?.tagJson) {
      const transformedTagJson = devices.DeviceTemplate.tagJson.reduce(
        (acc: any, tag: any) => {
          acc[tag.tagName] = tag;
          return acc;
        },
        {}
      );
      setCustomTagData(transformedTagJson);
    }
    fetchData();
  }, []);
  const timestamp = dashboardSts?.timestamp ? formatTimestamp(dashboardSts?.timestamp) : "No data received yet";
  console.log(customTagData)
  return (
    <div>
      {loader && <Loader4 openLoader={loader} setOpenLoader={setLoader} />}

      <p className="flex p-[10px] mb-[20px]">
        <MobileTowerIcon levels={+dashboardSts?.rssi || 0} time={timestamp} />
        <p className="ml-2">
          {"    Last Data Received at  " + timestamp ||
            "  YYYY-MM-DD HH:MM:SS"}
        </p>
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 border border-1 rounded-md p-2">
        {tags.map((tag, index) => 

          
            {return customTagData?.[tag]?.sensorStatus && (
              tag === "tag1" || tag === "tag2" || tag === "tag3" ? (
                <>
                  <div
                    className="rounded-md border border-stroke p-5 bg-white "
                  // style={{ boxShadow: "lightgrey 3px 3px 30px 5px" }}
                  >
                    <h2 className="font-medium" style={{ whiteSpace: "nowrap" }}>{customTagData?.[tag]?.sensorAlias || "tag name"}</h2>

                    <div className="flex items-end justify-between ">
                      <div>
                        <h4
                          className="text-title-md text-3xl"
                          style={{ color: customTagData?.[tag]?.sensorStatus ? "green" : "red" }}
                        >
                          {customTagData?.[tag]?.sensorStatus ? "ON" : "OFF"}
                        </h4>
                      </div>
                      <Switch
                        className="ml-20"
                        checked={customTagData?.[tag]?.sensorStatus}
                        onChange={() => { handleTagChange(customTagData?.[tag]?.sensorAlias) }}
                        color="primary"
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "green",
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                            backgroundColor: "green",
                          },
                          "& .MuiSwitch-track": {
                            backgroundColor: "red",
                          },
                        }}
                      />
                    </div>
                  </div>
                </>


              ) : (
                <CardTwo
                  title={customTagData?.[tag]?.sensorAlias || "N/A"}
                  amount={`${dashboardSts?.[tag] || "0.0"} ${customTagData?.[tag]?.sensorUnit || "N/A"}`}
                />
              )
            )}
       
        )}
      </div>

      {(customTagData?.["tag1"]?.sensorStatus || customTagData?.["tag2"]?.sensorStatus || customTagData?.["tag3"]?.sensorStatus) && (
        <Group1 customTagData={customTagData} apiData={apiData} devices={devices} />
      )}

      {(customTagData?.["tag4"]?.sensorStatus || customTagData?.["tag5"]?.sensorStatus || customTagData?.["tag6"]?.sensorStatus || customTagData?.["tag7"]?.sensorStatus) && (
        <Group2 customTagData={customTagData} apiData={apiData} devices={devices} />
      )}

      {(customTagData?.["tag8"]?.sensorStatus || customTagData?.["tag9"]?.sensorStatus || customTagData?.["tag10"]?.sensorStatus || customTagData?.["tag11"]?.sensorStatus) &&
        (<Group3 customTagData={customTagData} apiData={apiData} devices={devices} />)

      }
      {(customTagData?.["tag12"]?.sensorStatus || customTagData?.["tag13"]?.sensorStatus || customTagData?.["tag14"]?.sensorStatus || customTagData?.["tag15"]?.sensorStatus) &&
        (<Group4 customTagData={customTagData} apiData={apiData} devices={devices} />)

      }
      {(customTagData?.["tag16"]?.sensorStatus || customTagData?.["tag17"]?.sensorStatus || customTagData?.["tag18"]?.sensorStatus || customTagData?.["tag19"]?.sensorStatus) &&
        (<Group5 customTagData={customTagData} apiData={apiData} devices={devices} />)

      }
      {(customTagData?.["tag20"]?.sensorStatus || customTagData?.["tag21"]?.sensorStatus || customTagData?.["tag22"]?.sensorStatus || customTagData?.["tag23"]?.sensorStatus) &&
        (<Group6 customTagData={customTagData} apiData={apiData} devices={devices} />)

      }
      {(customTagData?.["tag24"]?.sensorStatus || customTagData?.["tag25"]?.sensorStatus || customTagData?.["tag26"]?.sensorStatus || customTagData?.["tag27"]?.sensorStatus) &&
        (<Group7 customTagData={customTagData} apiData={apiData} devices={devices} />)

      }

    </div>
  )
}

export default CustomTag19