import { TableCell, TableHead, TableRow } from "@mui/material";
import { useUserState } from "../../../app-context/auth-context/AuthState";
import { useMemo } from "react";

// Table Header
const baseTableCellsData = [
  { label: "IMEI No", id: "serialnum" },
  { label: "Device Name", id: "deviceName" },
  { label: "Device Alias", id: "deviceAlias" },
  // { label: "Customer Name", id: "customerName" },
  { label: "Location(lat,long)", id: "location" },
  { label: "Status", id: "status" },
  { label: "Action", id: "action" },
];


export function EnhancedTableHead() {
  const { users } = useUserState();
  const role = users?.user?.role || 2; // Default role if undefined

  const tableCellsData = useMemo(() => {
    if (role === 1) {
      const updatedTableCells = [...baseTableCellsData];

      // Insert "Customer Name" at the 4th index
      updatedTableCells.splice(3, 0, { label: "Customer Name", id: "customerName" });

      // Insert "Emqx stats" at the 6th index
      updatedTableCells.splice(5, 0, { label: "Emqx stats", id: "emqxStats" });

      return updatedTableCells;
    }
    return baseTableCellsData;
  }, [role]);

  return (
    <TableHead className="bg-gray-800">
      <TableRow className="border-b">
        {tableCellsData.map((cellData) => (
          <TableCell
            key={cellData.id}
            padding="normal"
            className="p-2"
            sx={{ color: "white", fontWeight: 600, textAlign: "center" }}
          >
            {cellData.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
