import  { useState } from 'react';
import exportExcel from '../../utils/export/excel-export';

const baseUrl = process.env.REACT_APP_BASE_URL;
const CumulativeReport = () => {
    // State to manage the loading state
    const [isLoading, setIsLoading] = useState(false);

    // Simulate the report download process
    const handleDownload = async () => {
        setIsLoading(true); // Start loading
        try {
            let queryParams = `/scheduler-response?key=EXCEL_REPORT`;
            await exportExcel(
                baseUrl + queryParams,
                (progress) => {
                    //   setDownloadProgress(progress);
                },
                "GET",
                {},
                "cumulative.xlsx"
            );
        } catch (error) {
            console.log(error);

        } finally{
            setIsLoading(false)
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
            <h1 className="text-3xl font-bold text-center text-gray-700 mb-4">Cumulative Report</h1>

            {/* Button to trigger download */}
            <button
                onClick={handleDownload}
                disabled={isLoading}
                className={`w-64 py-3 px-6 rounded-lg font-medium transition duration-300 
          ${isLoading ? 'bg-gray-300 text-gray-600 cursor-not-allowed' : 'bg-blue-600 text-white hover:bg-blue-700'}`}
            >
                {isLoading ? 'Processing...' : 'Download Cumulative Report'}
            </button>

            {/* Loading message when downloading */}
            {isLoading && <p className="mt-4 text-gray-500">Processing... Please wait.</p>}
        </div>
    );
};

export default CumulativeReport;
