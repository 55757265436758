import { Box, Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { http } from '../../../../helper/http';
import { toast } from "react-toastify";
import Loader from '../../../../components/Loader/Loader';


interface AddadmassRxProps {
  toggleForm: boolean;
  toggleFormFun: () => void;
  uniqueId: string;
  onUpdate?: () => void;
  deviceState?: any;
}

const baseUrl = process.env.REACT_APP_BASE_URL;

const AdmassRxForm: React.FC<AddadmassRxProps> = ({ toggleForm, toggleFormFun, deviceState }) => {
  const [admassRxId, setAdmassRxId] = useState<number | null>(null);
  const [actionState, setActionState] = useState("ADD");
  const [loader, setLoader] = React.useState(false);

  const [tags, setTags] = useState<{ [key: number]: { devices: any, tags: { id: string, name: string }[] } }>({});
  const [formState, setFormState] = useState([
    { imei: "", subFields: [{ registerNumber: "", tagId: "", tagName: "", deviceId: "", imei: "" }] },
  ]);
  const [interval, setInterval] = useState<number>(0);

  const addParentIMEI = () => {
    setFormState([...formState, { imei: "", subFields: [{ registerNumber: "", tagId: "", tagName: "", deviceId: "", imei: "" }] }]);
  };

  const removeParentIMEI = (index: number) => {
    setFormState(formState.filter((_, i) => i !== index));
    setTags(prevTags => {
      const updatedTags = { ...prevTags };
      delete updatedTags[index];
      return updatedTags;
    });
  };

  const updateIMEI = (index: number, value: string) => {
    setFormState(prevState => {
      const updatedForm = [...prevState];
      updatedForm[index].imei = value;
      return updatedForm;
    });
  };

  const addSubField = (parentIndex: number) => {
    setFormState(prevState => {
      const updatedForm = [...prevState];
      updatedForm[parentIndex].subFields.push({ registerNumber: "", tagId: "", tagName: "", deviceId: tags[parentIndex].devices.id, imei: tags[parentIndex].devices.deviceId });
      return updatedForm;
    });
  };

  const removeSubField = (parentIndex: number, subIndex: number) => {
    setFormState(prevState => {
      const updatedForm = [...prevState];
      updatedForm[parentIndex].subFields = updatedForm[parentIndex].subFields.filter(
        (_, i) => i !== subIndex
      );
      return updatedForm;
    });
  };

  const updateRegisterNumber = (parentIndex: number, subIndex: number, value: string) => {
    setFormState(prevState => {
      const updatedForm = [...prevState];
      updatedForm[parentIndex].subFields[subIndex].registerNumber = value;
      return updatedForm;
    });
  };

  const fetchTags = async (parentIndex: number) => {
    try {
      const imei = formState[parentIndex].imei;
      if (!imei) {
        console.warn("IMEI is empty, skipping fetch.");
        return;
      }

      const response: any = await http(`${baseUrl}/device?imei=${imei}`);
      const fetchedTags = response?.devices?.tags?.map((tag: any) => ({
        id: tag.Tag.id,
        name: tag.Tag.tagname,
      })) || [];

      setTags(prevData => ({
        ...prevData,
        [parentIndex]: {
          devices: response?.devices,
          tags: fetchedTags,
        },
      }));
      console.log(fetchedTags);
    } catch (error) {
      console.error("Failed to fetch tags", error);
    }
  };
  const handleTagChange = (parentIndex: number, subIndex: number, tagId: string, tagName: string) => {
    setFormState(prevState => {
      const updatedForm = [...prevState];
      updatedForm[parentIndex].subFields[subIndex].tagId = tagId;
      updatedForm[parentIndex].subFields[subIndex].tagName = tagName;
      updatedForm[parentIndex].subFields[subIndex].deviceId = tags[parentIndex].devices.id
      updatedForm[parentIndex].subFields[subIndex].imei = tags[parentIndex].devices.deviceId

      return updatedForm;
    });
  };


  const handleSubmit = async () => {

    try {
      const transformedData = formState.flatMap((parent) =>
        parent.subFields.map((sub) => ({
          deviceId: sub.deviceId,
          imei: parent.imei,
          registerNumber: sub.registerNumber,
          tagId: sub.tagId,
          tagname: sub.tagName,
        }))
      );
      const payload = { deviceId: deviceState.id, imei: deviceState.deviceId, timeInterval: interval, listOfConfig: transformedData }
      const url = `${baseUrl}/admassrx`;
      const resp = (await http(url, "POST", payload)) as any;
      toast.success(resp.message);
      toggleFormFun();
    } catch (error: any) {
      console.error("Error submitting form", error);
      toast.error(error);

    }
  };


  useEffect(() => {
    if (toggleForm && deviceState?.id) {
      const fetchAdmassRxData = async () => {
        setLoader(true)
        try {
          const url = `${baseUrl}/admassrx?deviceId=${deviceState.id}`;
          const response: any = await http(url);

          if (response?.admassRx) {
            setActionState("UPDATE");
            setAdmassRxId(response.admassRx.id);
            setInterval(response.admassRx.timeInterval || 0);
            console.log("here it is",response);
            const formattedData = response.admassRx.listOfConfig.reduce((acc: any[], config: any) => {
              const existingParent = acc.find((p) => p.imei === config.imei);

              if (existingParent) {
                existingParent.subFields.push({
                  registerNumber: config.registerNumber,
                  tagId: config.tagId.toString(),
                  tagName: config.tagname,
                  deviceId: config.deviceId,
                  imei: config.imei,
                });
              } else {
                acc.push({
                  imei: config.imei,
                  subFields: [{
                    registerNumber: config.registerNumber,
                    tagId: config.tagId.toString(),
                    tagName: config.tagname,
                    deviceId: config.deviceId,
                    imei: config.imei,
                  }],
                });
              }
              return acc;
            }, []);

            const result = response.admassRx.listOfConfig.reduce((acc: any, { imei, tagId, tagname, deviceId }: any) => {
              if (!acc[imei]) {
                acc[imei] = {
                  devices: {
                    id: deviceId,
                    deviceId: imei,
                    tags: []
                  },
                };
              }

              // Add the tag to the appropriate imei
              acc[imei].devices.tags.push({
                name: tagname,
                id: tagId
              });

              return acc;
            }, {});

            const result1 = Object.entries(result).reduce((acc: any, [imei, { devices }]: any, index) => {
              acc[index] = {
                devices,
                tags: devices.tags
              };
              return acc;
            }, {});

            setTags(result1);

            setFormState(formattedData);
            console.log("formated data", formattedData);


          } else {
            setActionState("ADD");
          }
        } catch (error) {

          console.error("Error fetching AdmassRx data", error);
        } finally {
          setLoader(false);
        }
      };
      fetchAdmassRxData();
    }
  }, [toggleForm, deviceState]);

  return (

    <Dialog open={toggleForm} onClose={toggleFormFun} fullWidth maxWidth="md">
      {loader && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="rgba(255, 255, 255, 0.7)"  // Light overlay
          zIndex={10}
        >
          <Loader />
        </Box>
      )}
      <DialogTitle>
        <h2 className="font-bold">
          {actionState === "ADD" ? "AdmassRX Creation" : "Update AdmassRX"}
        </h2>
      </DialogTitle>
      <DialogContent>
        <div className='flex space-x-3 justify-between items-center'>
          <h1 className='font-bold text-gray-600'>Device IMEI: {deviceState?.deviceId || "N/A"}</h1>
          <TextField
            label="Interval"
            placeholder="Enter Interval"
            margin="normal"
            name="interval"
            type="number"
            value={interval}
            onChange={(e) => setInterval(Number(e.target.value))}
            inputProps={{
              style: { height: "20px", padding: "16px 12px" },
            }}
          />
        </div>
        <Stack spacing={3} padding={3}>
          {formState.map((parent, parentIndex) => (
            <Card key={parentIndex} variant="outlined">
              <CardContent>
                <Stack direction="row" spacing={2} alignItems="center">
                  <TextField
                    label="IMEI"
                    variant="outlined"
                    value={parent.imei}
                    onChange={(e) => updateIMEI(parentIndex, e.target.value)}
                    fullWidth
                  />
                  <Button onClick={() => fetchTags(parentIndex)} variant="contained" color="primary">
                    Find
                  </Button>
                  {parentIndex !== 0 && (
                    <IconButton onClick={() => removeParentIMEI(parentIndex)} color="error">
                      <Remove />
                    </IconButton>
                  )}
                </Stack>
                <Stack spacing={2} marginTop={2}>
                  {parent.subFields.map((sub, subIndex) => (
                    <Stack key={subIndex} direction="row" spacing={2} alignItems="center">
                      <TextField
                        label="Register Number"
                        variant="outlined"
                        value={sub.registerNumber}
                        onChange={(e) => updateRegisterNumber(parentIndex, subIndex, e.target.value)}
                        fullWidth
                      />
                      <FormControl fullWidth>
                        <InputLabel>Select Tags</InputLabel>
                        <Select
                          value={sub.tagId || ""}
                          onChange={(e) => {
                            const selectedTag = tags[parentIndex]?.tags.find(tag => tag.id === e.target.value);
                            if (selectedTag) {
                              handleTagChange(parentIndex, subIndex, selectedTag.id, selectedTag.name);
                            }
                          }}
                        >
                          {/* Check if tags for the parentIndex are available */}
                          {(tags[parentIndex]?.tags || []).map((tag) => (
                            <MenuItem key={tag.id} value={tag.id}>
                              {tag.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {/* Only show the Remove button if subIndex is not 0 */}
                      {subIndex !== 0 && (
                        <IconButton onClick={() => removeSubField(parentIndex, subIndex)} color="error">
                          <Remove />
                        </IconButton>
                      )}
                    </Stack>
                  ))}
                </Stack>
              </CardContent>
              <CardActions>
                <Button onClick={() => addSubField(parentIndex)} startIcon={<Add />} variant="contained" color="primary">
                  Add SubField
                </Button>
              </CardActions>
            </Card>
          ))}
          <Button onClick={addParentIMEI} startIcon={<Add />} variant="contained" color="success">
            Add IMEI
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} sx={{ backgroundColor: "#155263", color: "#FFFFFF", "&:hover": { backgroundColor: "#5c5470" } }}>
          {actionState === "ADD" ? "Submit" : "Update"}
        </Button>
      </DialogActions>
    </Dialog>

  );
}

export default AdmassRxForm;
