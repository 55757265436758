import { useEffect, useState } from 'react'
import { http } from '../../../helper/http';
import NavigationIcon from '@mui/icons-material/Navigation';
import t101 from './assets/t101.png'
import feedpump from './assets/feedpump.png'
import flowmeter from './assets/flowmeter.png'
import micronfilter from './assets/micronfilter.png'
import ph from './assets/tds101.png'
import pressuremeter from './assets/pressuremeter.png'
import tds from './assets/tds.png'








const baseUrl = process.env.REACT_APP_BASE_URL;


// Define the type for the data object
type DeviceData = {
  [key: string]: string | undefined;
};

const DashboardSLD = ({
  deviceId,
  devices,
  selectedDevice,
}: any) => {


  useEffect(() => {

    fetchData();
  }, [selectedDevice])

  const [data, setData] = useState<DeviceData>({});

  const fetchData = async () => {
    let response: any = null;
    try {
      response = await http(
        baseUrl + `/device-data-log?deviceId=${selectedDevice}?last24=last24`
      );
      const _data = response
        .map((d: any) => {
          return {
            //timestamp: formatTimestamp(d.timeStamp),
            "FT-101": d["FT-101"],
            "TDS-101": d["TDS-101"],
            "PH-101": d["PH-101"],
            "PT-101": d["PT-101"],
            "PT-103": d["PT-103"],
            "PH-103": d["PH-103"],
            "FT-102": d["FT-102"],
            "TDS 102": d["TDS_102"],
            "FT-104": d["FT-104"],
            "TDS 104": d["TDS_104"],
            "PH-102": d["PH-102"],
            "PT-102": d["PT-102"],
            "FT-103": d["FT-103"],
            "TDS 103": d["TDS_103"],
          };
        })
      setData(_data[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>

      <div className=" relative bg-white shadow-md rounded-md w-full max-w-[100%] h-auto ">
        <h2 className="absolute left-10 top-10  bg-gray-600 text-white px-2 rounded-md font-inter font-medium text-lg leading-[29.05px] text-center w-[58px] h-[29px] mt-20 border">
          ETP
        </h2>
        <div className="  grid [grid-template-columns:repeat(15,1fr)] [grid-template-rows:repeat(10,80px)] p-5 ">
          <div className="box border-8 relative border-r-0 border-b-0 border-t-green-700 border-l-green-700  border-8 col-start-3 col-span-2 row-span-1">
            <NavigationIcon sx={{ fontSize: "35px", rotate: "270deg", marginBottom: "5px", position: "relative", left: "400px", zIndex: "1", marginTop: "-35px", color: "green" }} />
            <NavigationIcon sx={{ fontSize: "35px", rotate: "180deg", marginBottom: "5px", position: "absolute", top: "50px", left: "-20px", zIndex: "1", marginTop: "-18px", color: "green" }} />


          </div>
          <div className="relative box border-l-0 border-r-0 border-b-green-700 border-t-green-700 border-8 col-span-7 row-span-1">
            <div className='w-full absolute flex items-center justify-between -bottom-20'>
              {/* flow meter */}
              <div className=''>
                <div
                  className="h-[35px] border"
                  style={{ borderRadius: "10px", backgroundColor: "#008000" }}>
                  <h1 className="text-[8px] text-white text-center leading-[9.68px] mt-1">{data?.["FT-101"] || 0} kl/hr </h1>
                  <h1 className="text-[8px] text-white text-center leading-[9.68px] ml-1">15542.05 KL</h1>
                </div>
                <img
                  src={flowmeter}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16  imgs"
                />
                <div
                  className="h-[35px] border"
                  style={{ borderRadius: "10px" }}>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">P-101 NT1</h1>
                  <h1 className="text-[8px] text-center leading-[9.68px]">Feed Pump</h1>
                </div>

              </div>
              {/* Vertical Line */}
              <div className='relative  h-36  '>
                <div className='absolute -bottom-4 '>
                  <div className="w-3 bg-green-700  h-[59px] m-auto">
                    <div className="h-full border-l-2 border-dashed border-white ml-[3px] "></div>
                  </div>
                  <div className="w-[70px] h-[40px] m-auto"
                    style={{ backgroundColor: "#D9D9D9", borderRadius: "10px" }}>
                    <h1 className="text-[10px] text-center pt-1">
                      AS Dosing +</h1>
                    <h1 className="text-[10px] text-center leading-[8px]">HCL Dosing</h1>
                  </div>
                </div>
              </div>

              {/* micron meter */}
              <div className=''>
                <div
                  className="  h-[30px] border"
                  style={{ borderRadius: "10px" }}>
                  <h1 className="text-[8px] text-center leading-[9px] mt-1">MF-101 </h1>
                  <h1 className="text-[8px] text-center leading-[9px]">5 Micron Filter</h1>
                </div>

                <img
                  src={micronfilter}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16 imgs mt-2"
                />
              </div>
              {/* tds */}
              <div className=''>
                <div
                  className="h-[20px] border"
                  style={{ borderRadius: "5px", backgroundColor: "#008000" }}>
                  <h1 className="text-[10px] text-white text-center leading-[9.68px] mt-1">{data?.["TDS-101"] || 0} mg/L</h1>
                </div>
                <img
                  src={tds}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16 imgs my-2"
                />
                <div
                  className=" h-[20px] border "
                  style={{ borderRadius: "5px" }}>
                  <h1 className="text-[10px] text-center leading-[9.68px] mt-1">TDS 101</h1>
                </div>
              </div>
              {/* ph sensor */}
              <div className=' imgs'>
                <div
                  className="h-[20px] border"
                  style={{ borderRadius: "5px", backgroundColor: "#008000" }}>
                  <h1 className="text-[10px] text-white text-center leading-[9.68px] mt-1">PH {data?.["PH-101"] || 0}</h1>
                </div>
                <img
                  src={ph}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16 imgs my-2"
                />
                <div
                  className=" h-[40px] border "
                  style={{ borderRadius: "5px" }}>
                  <h1 className="text-[10px] text-center leading-[9.68px] mt-1">PH-101 </h1>
                  <h1 className="text-[10px] text-center leading-[9.68px] mt-1">PH Sensor</h1>
                </div>
              </div>
              {/* p-102 sensor */}
              <div className=' w-[64px]'>
                <div className="h-auto py-1"
                  style={{ backgroundColor: "#D9D9D9", borderRadius: "10px" }}>
                  <h1 className="text-[12px] text-center mt-1">P-102 </h1>
                  <h1 className="text-[12px] text-center">NT 1 HPP</h1>
                </div>


              </div>

            </div>

          </div>
          <div className="box border-t-green-700  border-l-0 border-r-0 border-b-0 border-8 col-span-3 row-span-1"></div>
          <div className="box border-8 relative border-t-green-700 border-r-green-700 border-b-green-700 border-l-0 col-span-15 row-span-8">
            <NavigationIcon sx={{ fontSize: "35px", rotate: "0deg", marginBottom: "5px", position: "absolute", top: "400px", right: "-20px", zIndex: "1", marginTop: "-35px", color: "green" }} />

          </div>
          <div className="relative box border-8 border-l-green-700 border-r-green-700 border-t-0 border-b-green-700 col-start-3 col-span-2">
            {/* NT Feed Tank*/}
            <div className='absolute -left-8 -bottom-12 '>
              <img
                src={t101}
                alt="Tank Type"
                className="h-4 sm:h-6 md:h-12 lg:h-16 imgs"
              />
              <div
                className=" mt-2 h-[35px] border "
                style={{ borderRadius: "10px" }}>
                <h1 className="text-[8px] text-center leading-[9.68px] mt-1">T-101</h1>
                <h1 className="text-[8px] text-center leading-[9.68px] ">NT Feed Tank</h1>
              </div>
            </div>
            {/* feed pump */}
            <div className='absolute -right-0 -bottom-12'>
              <img
                src={feedpump}
                alt="Tank Type"
                className="h-20 mt-[12px] imgs"
              />
              <div
                className="h-[35px] border"
                style={{ borderRadius: "10px" }}>
                <h1 className="text-[8px] text-center leading-[9.68px] mt-1">P-101 NT1</h1>
                <h1 className="text-[8px] text-center leading-[9.68px]">Feed Pump</h1>
              </div>
            </div>

          </div>
          <div className="relative box border-8 col-start-12 col-span-2 row-span-3 border-l-0 border-r-blue-500 border-b-blue-500 border-t-green-700">
          <NavigationIcon sx={{ fontSize: "35px", rotate: "180deg", marginBottom: "5px", position: "absolute", right: "-23px", zIndex: "1", top: "60px", color: "blue" }} />

            <div className='w-full absolute flex justify-between left-6 -top-0'>
              {/* pressure meter */}
              <div className='mt-[-75px] imgs'>
                <div
                  className="h-[20px] border"
                  style={{ borderRadius: "5px", backgroundColor: "#008000" }}>
                  <h1 className="text-[10px] text-white text-center leading-[9.68px] mt-1">{data?.["PT-101"] || 0} Bar</h1>
                </div>
                <img
                  src={pressuremeter}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16 imgs my-2"
                />
                <div
                  className="h-[40px] border "
                  style={{ borderRadius: "5px" }}>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">Pt-101 </h1>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">Pressure meter</h1>
                </div>
              </div>
              {/* nf-102 sensor */}
              <div className='mt-[-20px] imgs '>
                <div className="h-auto py-1"
                  style={{ backgroundColor: "#D9D9D9", borderRadius: "10px" }}>
                  <h1 className="text-[10px] text-center mt-1">NF-102 </h1>
                  <h1 className="text-[10px] text-center">NT system</h1>
                </div>
              </div>
            </div>

            <div className=' flex w-full absolute -bottom-[5rem] items-center justify-between '>
              {/*FT-102 flow meter */}
              <div className='imgs '>
                <div
                  className="h-[40px] border"
                  style={{ borderRadius: "5px", backgroundColor: "#008000" }}>
                  <h1 className="text-[8px] text-white text-center leading-[9.68px] mt-1">{data?.["FT-102"] || 0} kl/hr </h1>
                  <h1 className="text-[8px] text-white text-center leading-[9.68px] mt-1">15542.05 KL</h1>
                </div>
                <img
                  src={flowmeter}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16 my-2 imgs"
                />
                <div
                  className="h-[40px] border "
                  style={{ borderRadius: "5px" }}>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">FT-102</h1>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">Flow meter</h1>
                </div>
              </div>
              {/*TDS 102 */}
              <div className='imgs '>
                <div
                  className=" w-[70px] imgs h-[30px] border"
                  style={{ borderRadius: "5px", backgroundColor: "#008000" }}>
                  <h1 className="text-[10px] text-white text-center leading-[9.68px] mt-2">{data?.["TDS 102"] || 0}  mg/L</h1>
                </div>
                <img
                  src={tds}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16 imgs my-2"
                />
                <div
                  className=" h-[20px] border "
                  style={{ borderRadius: "5px" }}>
                  <h1 className="text-[10px] text-center leading-[9.68px] mt-1">TDS 102</h1>
                </div>

              </div>

            </div>
          </div>
          <div className="box relative border-8 row-span-7 border-t-orange-500 border-r-orange-500 border-l-0 border-b-0">
            <NavigationIcon sx={{ fontSize: "35px", rotate: "180deg", marginBottom: "5px", position: "absolute", top: "100px", right: "-20px", zIndex: "1", marginTop: "-35px", color: "orange" }} />
            <NavigationIcon sx={{ fontSize: "35px", rotate: "180deg", marginBottom: "5px", position: "absolute", top: "400px", right: "-20px", zIndex: "1", marginTop: "-35px", color: "orange" }} />



          </div>
          <div className="relative box border-8 col-span-10 col-start-2 row-start-5 border-l-blue-500 border-r-0 border-b-0 border-t-blue-500 row-span-2  ">
          <NavigationIcon sx={{ fontSize: "35px", rotate: "180deg", marginBottom: "5px", position: "absolute", left: "-23px", zIndex: "1", top: "40px", color: "blue" }} />




            <div className='flex justify-evenly items-center w-full absolute -top-16'>
              <div className='imgs '>
                <div
                  className="h-[20px] border"
                  style={{ borderRadius: "5px", backgroundColor: "#008000" }}>
                  <h1 className="text-[10px] text-white text-center leading-[9.68px] mt-1">{data?.["PT-103"] || 0} Bar</h1>
                </div>
                <img
                  src={pressuremeter}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16 imgs my-2"
                />
                <div
                  className="h-[40px] border w-[60px]"
                  style={{ borderRadius: "5px" }}>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">PT/HPS-103</h1>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">Pressure meter</h1>
                </div>
              </div>
              <div
                className="h-[50px] border w-[60px]  border-2"
                style={{ borderRadius: "10px", backgroundColor: "#D9D9D9" }}>
                <h1 className="text-[10px] text-center leading-[9.68px] mt-2">P-106</h1>
                <h1 className="text-[10px] text-center leading-[20px]">RO2HPP</h1>
              </div>
              {/* ph meter */}
              <div className=' imgs '>
                <div
                  className="mb-[4px] w-[60px] border"
                  style={{ borderRadius: "10px", backgroundColor: "#008000" }}>
                  <h1 className="text-[10px] text-white text-center leading-[9.68px] mt-1">{data?.["PH-103"] || 0} </h1>
                </div>
                <img
                  src={ph}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16  imgs"
                />
                <div
                  className="h-[35px] border mt-2"
                  style={{ borderRadius: "10px" }}>
                  <h1 className="text-[10px] text-center leading-[9.68px] mt-1">P-103</h1>
                  <h1 className="text-[10px] text-center leading-[9.68px]">PH Senser</h1>
                </div>

              </div>
              {/* micron meter */}
              <div className=' w-[60px]'>
                <div
                  className="  h-[35px] border"
                  style={{ borderRadius: "10px" }}>
                  <h1 className="text-[8px] text-center leading-[9px] mt-1">MF-103 </h1>
                  <h1 className="text-[8px] text-center leading-[9px]">5 Micron Filter</h1>
                </div>

                <img
                  src={micronfilter}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16  mt-2"
                />
              </div>
              {/*A/S + PH Dosing*/}
              <div
                className="h-[50px] border mt-[-20px] w-[60px]"
                style={{ borderRadius: "10px", backgroundColor: "#D9D9D9" }}>
                <h1 className="text-[10px] text-center leading-[9.68px] mt-2">A/S + PH </h1>
                <h1 className="text-[10px] text-center leading-[20px]"> Dosing</h1>
              </div>
              {/* feed pump */}
              <div className=''>

                <img
                  src={feedpump}
                  alt="Tank Type"
                  className="h-20 imgs "
                />
                <div
                  className="w-[56px]  h-[40px] border "
                  style={{ borderRadius: "5px" }}>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">P-105 RO2</h1>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">Feed Pump</h1>
                </div>
              </div>
              {/* T-104 RO2 */}
              <div className='imgs'>
                <img
                  src={t101}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16 imgs"
                />
                <div
                  className=" mt-2 h-[35px] border"
                  style={{ borderRadius: "10px" }}>
                  <h1 className="text-[9px] text-center leading-[9.68px] mt-1">T-104 RO2</h1>
                  <h1 className="text-[9px] text-center leading-[9.68px]">Feed Tank</h1>
                </div>

              </div>


            </div>

            {/*feed Tanks and Systems */}
            <div className='flex w-full justify-evenly items-center absolute -bottom-[5rem]'>

              {/* Ro-101 RO System */}

              <div
                className="h-[40px] border w-[60px]"
                style={{ borderRadius: "10px", backgroundColor: "#D9D9D9" }}>
                <h1 className="text-[10px] text-center leading-[9.68px] mt-2">Ro-101 </h1>
                <h1 className="text-[10px] text-center leading-[12px]">RO System</h1>
              </div>

              {/*FT-104 Flow meter*/}
              <div className='   imgs'>
                <div
                  className="h-[40px] border"
                  style={{ borderRadius: "5px", backgroundColor: "#008000" }}>
                  <h1 className="text-[8px] text-white text-center leading-[9.68px] mt-1">{data?.["FT-104"] || 0}  kl/hr </h1>
                  <h1 className="text-[8px] text-white text-center leading-[9.68px] mt-1">15542.05 KL</h1>
                </div>
                <img
                  src={flowmeter}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16 imgs"
                />
                <div
                  className="h-[35px] border mt-2"
                  style={{ borderRadius: "10px" }}>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">FT-104 </h1>
                  <h1 className="text-[8px] text-center leading-[9.68px]">Flow meter</h1>
                </div>

              </div>
              {/* TDS 104 */}
              <div className=' imgs'>
                <div
                  className=" w-[50px]  h-[30px] border"
                  style={{ borderRadius: "5px", backgroundColor: "#008000" }}>
                  <h1 className="text-[8px] text-white text-center leading-[9.68px] mt-2">{data?.["TDS 104"] || 0}  mg/L</h1>
                </div>
                <img
                  src={tds}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16 imgs my-2"
                />
                <div
                  className="w-[70px]  h-[20px] border "
                  style={{ borderRadius: "5px" }}>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">TDS 104</h1>
                </div>
              </div>
              <div className=' imgs'>
                <img
                  src={t101}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16 imgs"
                />
                <div
                  className=" mt-2  h-[35px] border"
                  style={{ borderRadius: "10px" }}>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">T-105 Ro permeate </h1>
                  <h1 className="text-[8px] text-center leading-[9.68px]">Storage Tank</h1>
                </div>
              </div>
              {/* T-105 Ro permeate Storage Tank*/}
              {/* P-107 SFT Feed Pump */}
              <div className=' imgs'>

                <img
                  src={feedpump}
                  alt="Tank Type"
                  className="h-20 "
                />
                <div
                  className="w-[60px]  h-[30px] border "
                  style={{ borderRadius: "5px" }}>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">P-107 SFT</h1>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">Feed Pump</h1>
                </div>
              </div>
              <div
                className="h-[40px] border w-[60px]"
                style={{ borderRadius: "10px", backgroundColor: "#D9D9D9" }}>
                <h1 className="text-[10px] text-center leading-[9.68px] mt-2">SFT-107</h1>
                <h1 className="text-[10px] text-center leading-[9.68px]"> Soft....</h1>
              </div>

              {/* T-107 SFT Storage Tank */}
              <div className='mt-[-40px] imgs'>
                <img
                  src={t101}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16 "
                />
                <div
                  className=" mt-2 w-[60px]  h-[35px] border"
                  style={{ borderRadius: "10px" }}>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">T-107 SFT</h1>
                  <h1 className="text-[8px] text-center leading-[12px]"> Storage Tank</h1>
                </div>

              </div>


            </div>
          </div>
          <div className="box border-8 col-span-1 col-start-2 row-span-1 row-start-7 border-r-orange-500  border-b-0 border-l-0 border-t-blue-500"></div>
          <div className="box border-8 col-span-8 row-span-2 row-start-7 border-t-sky-400/60 border-r-0  border-l-0 border-b-0"></div>
          <div className=" box  col-span-1 row-span-2 row-start-7 grid grid-col-1 ">
            <div className="box relative border-8  border-t-0  border-l-0  border-r-0 border-b-red-500 ">
            <NavigationIcon sx={{ fontSize: "35px", rotate: "90deg", marginBottom: "5px", position: "absolute", zIndex: "1",right:"30px", bottom: "-0px", color: "red" }} />

            </div>
            <div className="box border-8  border-t-0  border-b-0  border-r-0  border-l-red-500"></div>
          </div>
          <div className="box border-8 relative col-span-1 row-start-7 border-t-red-500 border-l-red-500 border-r-0 border-b-0  row-span-1">
            {/* T-107 SFT Storage Tank */}
            <div className=' absolute -right-10 -top-12 imgs'>
              <img
                src={t101}
                alt="Tank Type"
                className="h-4 sm:h-6 md:h-12 lg:h-16 "
              />
              <div
                className=" mt-2 w-[60px]  h-[35px] border"
                style={{ borderRadius: "10px" }}>
                <h1 className="text-[8px] text-center leading-[9.68px] mt-1">T-107 SFT</h1>
                <h1 className="text-[8px] text-center leading-[12px]"> Storage Tank</h1>
              </div>

            </div>
          </div>
          <div className="relative  box border-8 col-start-2 col-span-1 row-span-1 row-start-8 border-r-0 border-b-0 border-l-orange-500 border-t-orange-500">

            <NavigationIcon sx={{ fontSize: "35px", rotate: "180deg", marginBottom: "5px", position: "absolute", left: "-23px", zIndex: "1", top: "8px", color: "orange" }} />

          </div>


          <div className="relative box border-8 col-span-10 row-start-9  row-span-2 border-b-orange-500 border-r-0 border-l-orange-500 border-t-orange-500">
            <NavigationIcon sx={{ fontSize: "35px", rotate: "270deg", bottom: "-27px", marginBottom: "5px", position: "absolute", left: "100px", zIndex: "1", marginTop: "-35px", color: "orange" }} />
            <NavigationIcon sx={{ fontSize: "35px", rotate: "270deg", bottom: "-27px", marginBottom: "5px", position: "absolute", right: "100px", zIndex: "1", marginTop: "-35px", color: "orange" }} />
            <NavigationIcon sx={{ fontSize: "35px", rotate: "90deg", top: "12px", marginBottom: "5px", position: "absolute", left: "130px", zIndex: "1", marginTop: "-35px", color: "orange" }} />

            <div className='w-full flex absolute -top-10 justify-evenly items-start'>
              {/*T-102 NF2 Feed Tank */}
              <div className='imgs'>
                <img
                  src={t101}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16 imgs"
                />
                <div
                  className="h-[40px] border mt-2"
                  style={{ borderRadius: "5px" }}>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">T-102 NF2</h1>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1"> Feed Tank</h1>
                </div>
              </div>
              {/* feed pump */}
              <div className='imgs'>

                <img
                  src={feedpump}
                  alt="Tank Type"
                  className="h-20 imgs"
                />
                <div
                  className="h-[40px] border "
                  style={{ borderRadius: "5px" }}>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">P-103 RO2</h1>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">Feed Pump</h1>
                </div>
              </div>
              {/*A/S + PH Dosing*/}
              <div
                className="h-[50px] border w-[60px]"
                style={{ borderRadius: "10px", backgroundColor: "#D9D9D9" }}>
                <h1 className="text-[10px] text-center leading-[9.68px] mt-2">A/S + HCL</h1>
                <h1 className="text-[10px] text-center leading-[20px]"> Dosing</h1>
              </div>
              {/* 5micron meter */}
              <div className='mt-[-25px] imgs'>
                <div
                  className="  h-[35px] border"
                  style={{ borderRadius: "10px" }}>
                  <h1 className="text-[8px] text-center leading-[9px] mt-1">MF-102</h1>
                  <h1 className="text-[8px] text-center leading-[9px]">5 Micron Filter</h1>
                </div>

                <img
                  src={micronfilter}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16 imgs mt-2"
                />
              </div>
              {/*PH-102 PH Sensor*/}
              <div className='mt-[-35px] '>
                <div
                  className="  h-[30px] border"
                  style={{ borderRadius: "5px", backgroundColor: "#008000" }}>
                  <h1 className="text-[10px] text-white text-center leading-[9.68px] mt-2 ">{data?.["PH-102"] || 0}  mg/L</h1>
                </div>
                <img
                  src={ph}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16  my-2"
                />
                <div className="  h-[40px] border "
                  style={{ borderRadius: "5px" }}>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">PH-102</h1>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">PH Sensor</h1>
                </div>

              </div>

              {/*NF-102 NF System 2*/}
              <div
                className="h-[50px] border w-[65px]"
                style={{ borderRadius: "10px", backgroundColor: "#D9D9D9" }}>
                <h1 className="text-[10px] text-center leading-[9.68px] mt-2">P-104</h1>
                <h1 className="text-[10px] text-center leading-[20px]">NF2 HPP</h1>
              </div>
              {/* pressure meter */}
              <div className='imgs '>
                <div
                  className="h-[20px] border"
                  style={{ borderRadius: "5px", backgroundColor: "#008000" }}>
                  <h1 className="text-[10px] text-white text-center leading-[9.68px] mt-1">{data?.["PT-103"] || 0}  Bar</h1>
                </div>
                <img
                  src={pressuremeter}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16  my-2"
                />
                <div
                  className="h-[40px] border "
                  style={{ borderRadius: "5px" }}>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">PT/HPS-103</h1>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">Pressure meter</h1>
                </div>
              </div>

            </div>

          </div>
          <div className="relative box border-8 col-span-4 row-start-9  col-start-11 row-span-2 border-t-green-700 border-l-0 border-b-orange-500  border-r-orange-500">
            <div className='flex w-full items-center justify-between absolute -top-[5rem]'>
              {/*NF-102 NF System 2*/}
              <div
                className="h-[50px] border  w-[65px]"
                style={{ borderRadius: "10px", backgroundColor: "#D9D9D9" }}>
                <h1 className="text-[10px] text-center leading-[9.68px] mt-2">NF-102</h1>
                <h1 className="text-[10px] text-center leading-[20px]"> NF System 2</h1>
              </div>
              {/*FT-102 flow meter */}
              <div className=' imgs'>
                <div
                  className="h-[40px] border"
                  style={{ borderRadius: "5px", backgroundColor: "#008000" }}>
                  <h1 className="text-[8px] text-white text-center leading-[9.68px] mt-1">{data?.["FT-102"] || 0} kl/hr </h1>
                  <h1 className="text-[8px] text-white text-center leading-[9.68px] mt-1">15542.05 KL</h1>
                </div>
                <img
                  src={flowmeter}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16 my-2 imgs"
                />
                <div
                  className="h-[40px] border "
                  style={{ borderRadius: "5px" }}>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">FT-102</h1>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">Flow meter</h1>
                </div>
              </div>
              {/*TDS 102 */}
              <div className=' imgs '>
                <div
                  className=" w-[70px] imgs h-[30px] border"
                  style={{ borderRadius: "5px", backgroundColor: "#008000" }}>
                  <h1 className="text-[10px] text-white text-center leading-[9.68px] mt-2">{data?.["TDS 102"] || 0}  mg/L</h1>
                </div>
                <img
                  src={tds}
                  alt="Tank Type"
                  className="h-4 sm:h-6 md:h-12 lg:h-16 imgs my-2"
                />
                <div
                  className=" h-[20px] border "
                  style={{ borderRadius: "5px" }}>
                  <h1 className="text-[10px] text-center leading-[9.68px] mt-1">TDS 102</h1>
                </div>

              </div>


            </div>

          </div>
        </div>



      </div>

    </>
  )
}

export default DashboardSLD