import { useEffect, useState } from "react";
import { http } from "../../../helper/http";
import CardTwo from "../../../components/dashBoardComponents/CardTwo";
import MobileTowerIcon from "../../../components/apex-chart/HeatMapChart";
import { formatTimestamp } from "../../../common-function/CommonFunction";
import Loader4 from "../../../components/Loader/Loader4";

const baseUrl = process.env.REACT_APP_BASE_URL;
const tags = ['40000', '40004', "40008", '40012', "40016", '40020'];

const DashboardAdmassRx = ({ devices, selectedDevice }: any) => {
    const [dashboardSts, setDashboardSts] = useState<Record<string, string>>({});
    const [customTagData, setCustomTagData] = useState<any>([]);
    const [loader, setLoader] = useState(false)

    const fetchData = async () => {
        try {
            setLoader(true)
            const response = (await http(
                baseUrl + `/device-data-log?deviceId=${selectedDevice}&last24=last24`
            )) as any;
            setDashboardSts(response.length ? response[0] : {});
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoader(false)
        }
    };



    useEffect(() => {
        if (devices?.DeviceTemplate?.tagJson) {
            const transformedTagJson = devices.DeviceTemplate.tagJson.reduce(
                (acc: any, tag: any) => {
                    acc[tag.tagName] = tag;
                    return acc;
                },
                {}
            );
            setCustomTagData(transformedTagJson);
        }
        fetchData();
    }, []);
    const timestamp = dashboardSts?.timeStamp ? formatTimestamp(dashboardSts?.timeStamp) : "No data received yet";

    return (
        <div className="p-6">
            {loader && <Loader4 openLoader={loader} setOpenLoader={setLoader} />}
            <p className="flex p-[10px] mb-[20px]">
                <MobileTowerIcon levels={+dashboardSts?.rssi || 0} time={timestamp} />
                <p className="ml-2">
                    {"    Last Data Received at  " + timestamp ||
                        "  YYYY-MM-DD HH:MM:SS"}
                </p>
            </p>
            <h2 className="text-xl font-semibold mb-4">Dashboard Admass RX</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 border border-1 rounded-md p-2">
                {tags.map((tag, index) => (
                    <div key={index}>
                        {customTagData?.[tag]?.sensorStatus &&
                            <CardTwo
                                title={customTagData?.[tag]?.sensorAlias || 'N/A'}
                                amount={`${dashboardSts?.[tag] || "0.0"} ${customTagData?.[tag]?.sensorUnit || 'N/A'}`}
                            />
                        }
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DashboardAdmassRx;