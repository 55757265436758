import { useEffect, useState } from 'react'
import { http } from '../../helper/http'
import Customers from '../admin/customer/Customers'
import { useNavigate } from 'react-router-dom'

const cardContainer: any = {
    display: 'flex',
    flexWrap: 'no-wrap'
}

const card: any = {
    width: '25%',
    marginRight: '24px',
    border: '2px solid lightgrey',
    borderRadius: '16px',
    padding: '20px'
}

const cardTitle: any = {
    fontSize: '18px',
    fontWeight: '600'
}

const cardValue: any = {
    fontSize: '28px',
    fontWeight: '600'
}


interface CardData {
    customerCount: number | string;
    deviceCount: number | string;
    onlineDevices: number | string;
    offlineDevices: number | string;
}

const AdminDashboard1 = () => {
    const navigate = useNavigate();

    const [cardData, setCardData] = useState<CardData>({
        customerCount: '',
        deviceCount: '',
        onlineDevices: '',
        offlineDevices: '',
    });
    const [schedulerResponse, setSchedulerResponse] = useState<any>({
        succesCount: 0,
        failedCount: 0
    });


    async function getCarddData() {
        try {
            const resp = await http('/admin-dashboard') as any;
            setCardData((prevState: any) => ({
                ...prevState,
                customerCount: resp.customerCount,
                deviceCount: resp.deviceCount,
            }));
        } catch (err) {
            console.error(err);
        }
    }

    async function getDeviceCount() {
        try {
            const resp1 = await http('/admin-device-dashboard') as any;
            setCardData((prevState: any) => ({
                ...prevState,
                onlineDevices: resp1.onlineDevices,
                offlineDevices: resp1.offlineDevices,
            }));
        } catch (err) {
            console.error(err);
        }
    }

    const handleRedirectClick = (status: string) => {
        navigate(`/${status}-devices`, { state: { status } });
    }

    async function getSchedulerResponse() {
        try {
            const resp = await http('/scheduler-response?key=STATS') as any;
            setSchedulerResponse(resp)

        } catch (err) {
            console.error(err);
        }
    }

    const handleRedirectScheduleResponse = () =>{
        navigate('/schedule-response');
    }


    useEffect(() => {
        getCarddData()
        getDeviceCount()
        getSchedulerResponse()
    }, []);



    return (
        <>
            <div style={cardContainer}>
                <div style={card}>
                    <p style={cardTitle}>Customer Count</p>
                    <p style={cardValue}>{cardData.customerCount}</p>
                </div>
                <div style={card}>
                    <p style={cardTitle}>Device Count</p>
                    <p style={cardValue}>{cardData.deviceCount}</p>
                </div>
                <div style={card} className='cursor-pointer' onClick={() => handleRedirectClick('online')}>
                    <p style={cardTitle}>Online Devices</p>
                    <p style={cardValue}>{cardData.onlineDevices}</p>
                </div>
                <div style={card} className='cursor-pointer' onClick={() => handleRedirectClick('offline')}>
                    <p style={cardTitle}>Offline Devices</p>
                    <p style={cardValue}>{cardData.offlineDevices}</p>
                </div>
                <div className='cursor-pointer' style={card} onClick={handleRedirectScheduleResponse}>
                    <p style={cardTitle}>(SUCCESS/FAILED)</p>
                    <p style={cardValue}>{`${schedulerResponse.succesCount}/${schedulerResponse.failedCount}`}</p>
                </div>
            </div>
            <br /><br />
            <Customers />
        </>
    )
}

export default AdminDashboard1