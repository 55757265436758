
export const handleColumnName = (device: any, role: number, customTag1: any) => {
    const template = +device.template
    let tags: any = []
    let baseHeaders: any = []
    switch (template) {
        case 29:
            tags = ["Level"];
            baseHeaders = [
                "#",
                "TimeStamp",
                ...(+role === 1
                    ? tags.map((tag: any) => `${customTag1?.[tag]?.sensorAlias || tag} (${customTag1?.[tag]?.sensorUnit || ""})`)
                    : tags
                        .filter((tag: any) => customTag1?.[tag]?.sensorStatus)
                        .map((tag: any) => `${customTag1[tag].sensorAlias} (${customTag1[tag].sensorUnit})`)
                )
            ];
            if (role === 1) {
                return [...baseHeaders, "RSSI"];
            } else {
                return baseHeaders;
            }
        case 55:
            tags = ["tag1", "tag2", "tag3", "tag4", "tag5", "tag6", "tag7", "tag8", "tag9",
                "tag10", "tag11", "tag12", "tag13", "tag14", "tag15", "tag16", "tag17", "tag18", "tag19",
                "tag20", "tag21", "tag22", "tag23", "tag24", "tag25", "tag26", "tag27"];

            baseHeaders = [
                "#",
                "TimeStamp",
                ...(+role === 1
                    ? tags.map((tag: any) => `${customTag1?.[tag]?.sensorAlias || tag} (${customTag1?.[tag]?.sensorUnit || ""})`)
                    : tags
                        .filter((tag: any) => customTag1?.[tag]?.sensorStatus)
                        .map((tag: any) => `${customTag1[tag].sensorAlias} (${customTag1[tag].sensorUnit})`)
                )
            ];
            if (role === 1) {
                return [...baseHeaders, "RSSI"];
            } else {
                return baseHeaders;
            }

        case 45:
            tags = ["tag1", "tag2", "tag3", "tag4"];

            baseHeaders = [
                "#",
                "TimeStamp",
                ...(+role === 1
                    ? tags.map((tag: any) => `${customTag1?.[tag]?.sensorAlias || tag} (${customTag1?.[tag]?.sensorUnit || ""})`)
                    : tags
                        .filter((tag: any) => customTag1?.[tag]?.sensorStatus)
                        .map((tag: any) => `${customTag1[tag].sensorAlias} (${customTag1[tag].sensorUnit})`)
                )
            ];
            if (role === 1) {
                return [...baseHeaders, "RSSI"];
            } else {
                return baseHeaders;
            }
        case 58:
            baseHeaders = [
                "#",
                "TimeStamp",
                "Temperature (°C)",
                "Humidity (%)",
                "Set Temp (°C)",
                "Status",
            ];

            if (role === 1) {
                return [...baseHeaders, "RSSI"];
            } else {
                return baseHeaders;
            }
        default:
            return []
    }

}