import dayjs from "dayjs";

export const handleData = (device: any, role: number, customTag1: any, resp: any) => {
    const template = +device.template
    let tags: any = []
    let _data: any = []
    switch (template) {
        case 29:
            tags = ["Level"];
            _data = resp.map((d: any) => {
                return {
                    logId: d.logId,
                    TimeStamp: formatTimestamp(d.timeStamp),
                    ...Object.fromEntries(
                        (role === 1
                            ? tags
                            : tags.filter((tag: any) => customTag1?.[tag]?.sensorStatus)
                        ).map((tag: any) => [
                            `${customTag1?.[tag]?.sensorAlias || tag} (${customTag1?.[tag]?.sensorUnit || ""})`,
                            d[tag],
                        ])
                    ),
                    ...(role === 1 && { RSSI: d["rssi"] }),
                };
            });
            return _data
        case 55:
            tags = ["tag1", "tag2", "tag3", "tag4", "tag5", "tag6", "tag7", "tag8", "tag9", "tag10",
                 "tag11", "tag12", "tag13", "tag14", "tag15", "tag16", "tag17", "tag18", "tag19",
                 "tag20", "tag21", "tag22", "tag23", "tag24", "tag25", "tag26", "tag27"];

            _data = resp.map((d: any) => {
                return {
                    logId: d.logId,
                    TimeStamp: formatTimestamp(d.timeStamp),
                    ...Object.fromEntries(
                        (role === 1
                            ? tags
                            : tags.filter((tag: any) => customTag1?.[tag]?.sensorStatus)
                        ).map((tag: any) => [
                            `${customTag1?.[tag]?.sensorAlias || tag} (${customTag1?.[tag]?.sensorUnit || ""})`,
                            d[tag],
                        ])
                    ),
                    ...(role === 1 && { RSSI: d["rssi"] }),
                };
            });
            return _data

            case 45:
                tags = ["tag1", "tag2", "tag3", "tag4"];
    
                _data = resp.map((d: any) => {
                    return {
                        logId: d.logId,
                        TimeStamp: formatTimestamp(d.timeStamp),
                        ...Object.fromEntries(
                            (role === 1
                                ? tags
                                : tags.filter((tag: any) => customTag1?.[tag]?.sensorStatus)
                            ).map((tag: any) => [
                                `${customTag1?.[tag]?.sensorAlias || tag} (${customTag1?.[tag]?.sensorUnit || ""})`,
                                d[tag],
                            ])
                        ),
                        ...(role === 1 && { RSSI: d["rssi"] }),
                    };
                });
                return _data
        case 58:
            _data = resp.map((d: any) => {
                return {
                    logId: d.logId,
                    TimeStamp: formatTimestamp(d.timeStamp),
                    "Temperature (°C)": d["temp"],
                    "Humidity (%)": d["hum"],
                    Status: d["ac_status"],
                    "Set Temp (°C)": d["set_temp"],
                    RSSI: d["rssi"],
                };
            });
            return _data
        default:
            return _data
    }

}
function formatTimestamp(timestamp: string) {
    return dayjs(timestamp).format("YYYY-MM-DD HH:mm:ss");
}