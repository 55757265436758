import { useEffect, useRef, useState } from "react";
import { Switch } from "@mui/material";
// import { http } from "../../helper/http";
import {
  formatTimestamp,
  generateCSV1,
  reportDataWithGraph,
} from "../../common-function/CommonFunction";
import { toast } from "react-toastify";
import axios from "axios";
import LongMenu from "../../utils/longMenu/LongMenu";
import SteplineChart from "../../components/apex-chart/SteplineChart";
import Loader4 from "../../components/Loader/Loader4";
import { io } from "socket.io-client";

const baseUrl = process.env.REACT_APP_BASE_URL;
const DashboardAutomation2 = ({ selectedDevice, deviceId, devices }: any) => {
  const [apiData, setApiData] = useState<any[]>([]);
  const [dashboardSts, setDashboardSts] = useState<any>([]);
  const [pumpStatus, setPumpStatus] = useState(false);
  const chartRef = useRef<HTMLDivElement>(null);
  const [openLoader, setOpenLoader] = useState(false);
  const automationIORef = useRef<any>(null);
  const responseTimeoutRef: any = useRef<NodeJS.Timeout | null>(null);
  const [customTagData, setCustomTagData] = useState<any>([]);


  const fetchData = async () => {
    const accessToken = localStorage.getItem("token");
    let response: any = null;
    try {
      setOpenLoader(true)
      response = await axios.get(baseUrl + `/device-data-log?last24=last24`, {
        params: {
          deviceId: selectedDevice,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const _data = response.data.map((d: any, i: any) => {
        return {
          timestamp: formatTimestamp(d.timeStamp),
          devicestatus: d["relay_1"],
        };
      });

      setPumpStatus(_data.length ? _data[0].devicestatus === "on" : false);
      setApiData(_data);
      setDashboardSts(_data.length ? _data[0] : "");
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setOpenLoader(false)
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice]);


  const pump1line =
    apiData.length > 0
      ? apiData.map(({ timestamp, devicestatus }: any) => ({
        timestamp,
        status: devicestatus,
      }))
      : [];


  const handleAutomationResponse = () => {
    if (automationIORef.current) {
      automationIORef.current.disconnect();
      automationIORef.current = null;
    }
    const IO: any = io(`${baseUrl}/automation_2?deviceId=${devices.deviceId}`);
    automationIORef.current = IO;

    IO.on("automation_2-response", (data: any) => {
      if (data) {
        clearTimeout(responseTimeoutRef.current);
        setOpenLoader(false);
        if (data.relay_1 === "success") {
          toast.success(`Auatomation ${data.relay_1} successfully.`);
          setPumpStatus(data.relay_1 === "success" ? true : false);
          setTimeout(() => {
            fetchData();
          }, 5000); // 1000ms = 1 second
          
        } else if (data.relay_1 === "fail") {
          toast.error("Automation Failed. Ensure remote pairing is configured correctly or consult the administrator.")
        } else {
          toast.error("Automation failed");
        }
        console.log(data);
      }

    });

    return () => {
      if (automationIORef.current) {
        automationIORef.current.disconnect();
        automationIORef.current = null;
      }
    };
  };

  const handleChange = async () => {
    try {
      const message = { relay_1: !pumpStatus ? "on" : "off" };
      try {
        setOpenLoader(true);
        automationIORef.current.emit("automation_2-publish", message);
        responseTimeoutRef.current = setTimeout(() => {
          toast.warning("Gateway communication failed.");
          setOpenLoader(false);
        }, 30000);
      } catch (error) {
        console.error(error);
      }
    } catch (err: any) {
      toast.error(err);
    }
  };

  useEffect(() => {
    if (devices?.DeviceTemplate?.tagJson) {
      const transformedTagJson = devices.DeviceTemplate.tagJson.reduce(
        (acc: any, tag: any) => {
          acc[tag.tagName] = tag;
          return acc;
        },
        {}
      );
      setCustomTagData(transformedTagJson);
    }
    handleAutomationResponse();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice, devices.deviceId]);


  const handleExportBarChart = async (exportOption: string) => {
    const filter = pump1line.map(({ timestamp, status }) => {
      return {
        timestamp,
        Pump_Staus: status,
      };
    });

    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(filter);
        break;
      case "Download as XLS":
        break;
      case "Download as PDF":
        if (filter && chartRef) {
          reportDataWithGraph(
            chartRef,
            filter,
            devices?.deviceName,
            devices?.Customer?.name,
            "Automation Summary",
            "Realtime - Last 24 Hour | IST"
          );
        } else {
        }
        return;
      default:
        return; // Unknown export option
    }
  };



  return (

    <div>
      <Loader4 openLoader={openLoader} setOpenLoader={setOpenLoader} />

      <div className=" p-2 mb-4">
        <section>
          <p className=" flex ">
            {"    Last Data Sent at  " + dashboardSts?.timestamp ||
              "  YYYY-MM-DD HH:MM:SS"}
          </p>
        </section>
      </div>

      <div className="border border-gray-400 rounded-md grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 p-2">
        <div
          className="rounded-md border border-stroke p-6 bg-white shadow-lg"
          style={{ boxShadow: "lightgrey 3px 3px 30px 5px" }}
        >
          <h2 className="font-medium" style={{ whiteSpace: "nowrap" }}>{customTagData?.["relay_1"]?.sensorAlias || "Relay 1"}</h2>

          {customTagData?.["relay_1"]?.sensorStatus &&
            <div className="mt-2 flex items-end justify-between">
              <div>
                <h4
                  className="text-title-md text-3xl"
                  style={{ color: pumpStatus ? "green" : "red" }}
                >
                  {pumpStatus ? "ON" : "OFF"}{" "}
                  {/* Display text indicating switch state */}
                </h4>
              </div>
              <Switch
                className="ml-20"
                checked={pumpStatus}
                onChange={handleChange}
                color="primary"
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "green",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "green",
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: "red",
                  },
                }}
              />
            </div>
          }
        </div>
      </div>
      <div className="pt-[3px]"></div>
      {customTagData?.["relay_1"]?.sensorStatus &&
        <div
          className="border border-gray-400 rounded-md p-2"
        // style={{ border: "2px solid #EAECF0", padding: "10px" }}
        >
          <div className="mt-7 mb-11">
            <div className="relative">
              <h1 className="relative  h-fit  p-2">
                Data variation with time (between{" "}
                {apiData.length > 0
                  ? apiData[apiData.length - 1].timestamp +
                  " to " +
                  apiData[0].timestamp
                  : " "}
                )
              </h1>
              <div className="absolute top-0 right-0">
                <LongMenu
                  options={[
                    "Download as CSV",
                    "Download as PDF",
                  ]}
                  onOptionClick={handleExportBarChart}
                  id={1}
                />
              </div>
            </div>
            <div ref={chartRef || ""}>
              {/* <LineChart1
                  data={pump1data}
                  yAxisName="Pump Status"
                  xAxisName="Time"
                /> */}
              <SteplineChart data={pump1line.reverse()} yAxisLabel="Device Status" xAxisLabel="Time" />
            </div>
          </div>
        </div>
      }
    </div>

  );
};

export default DashboardAutomation2;
