import {
    Box,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tabs,
    Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { http } from "../../helper/http";
import { FileDownload } from "@mui/icons-material";
import moment from "moment";
import exportExcel from "../../utils/export/excel-export";

const tableCellsData = [
    { label: "IMEI No", id: "deviceId" },
    { label: "Device Name", id: "deviceName" },
    { label: 'Device Alias', id: 'deviceAlias' },
    { label: "Status", id: "status" },
    { label: "RSSI", id: "rssi" },
    { label: "Last Time", id: "updatedAt" },

];


export interface ApiResponseDeviceStatus {
    rows: any[];
    currentPage: number;
    totalPages: number;
    totalItems: number;
}

const baseUrl = process.env.REACT_APP_BASE_URL;

function ScheduleResponse() {

    const [searchQuery, setSearchQuery] = useState<string>("");
    const [devices, setDevices] = useState<ApiResponseDeviceStatus | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [downloadProgress, setDownloadProgress] = useState<number>(0);
    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
        if (newValue === 0) {
            fetchDevices(0, 'SUCCESS')
        } else {
            fetchDevices(0, 'FAILED')
        }

    };

    const fetchDevices = async (page: number, status: any = "SUCCESS") => {
        setLoading(true);

        try {
            const response = await http(
                `${baseUrl}/scheduler-response?status=${status}&page=${page + 1}&limit=10&search=${searchQuery}`
            );

            const responseData = response as any;

            setDevices(responseData);
        } catch (err) {
            console.error("Error fetching devices:", err);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (event: unknown, newPage: number) => {        
        setPage(newPage);
        if (selectedTab === 0) {
            fetchDevices(page, 'SUCCESS')
        } else {
            fetchDevices(page, 'FAILED')
        }
        
    };

    const handleExport = async () => {
        try {
            let queryParams = `/scheduler-response?key=EXCEL&status=${selectedTab===0 ? 'SUCCESS' : 'FAILED'}&`;
            if (searchQuery) {
                queryParams += `&search=${searchQuery}`;
            }
            await exportExcel(
                baseUrl + queryParams,
                (progress) => {
                  setDownloadProgress(progress);
                },
                "GET",
                {},
                "devices-status.xlsx"
              );

            setDownloadProgress(0);
        } catch (error) {
            setDownloadProgress(0);
        } finally {
            setDownloadProgress(0);
        }
    };

    useEffect(() => {
        // Reset to the first page whenever the tab changes
        setPage(0);
        if (selectedTab === 0) {
            fetchDevices(0, 'SUCCESS');
        } else {
            fetchDevices(0, 'FAILED');
        }
    }, [selectedTab, searchQuery]);
    

    
    return (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Paper sx={{ width: "99%", padding: "10px" }}>
                <div className="flex justify-between items-center">
                    <Typography
                        sx={{
                            flex: "1 1 100%",
                            fontWeight: 700,
                            fontSize: [
                                "text-sm",
                                "text-md",
                                "text-lg",
                                "text-xl",
                                "text-2xl",
                                "text-3xl",
                            ],
                        }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                        className="shadow-gray-500 text-black font-bold text-decoration-uppercase capitalize"
                    >
                        Schedule Response
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            aria-label="device selection tabs"
                            sx={{
                                marginTop: 2,
                            }}
                        >
                            <Tab label="Success" />
                            <Tab label="Failed" />
                        </Tabs>
                    </Typography>

                    <div className="flex w-1/2">
                        <input
                            type="search"
                            placeholder="Search..."
                            name="search"
                            value={searchQuery}
                            onChange={(event) => {
                                setSearchQuery(event.target.value);

                                setPage(0);
                            }}
                            className="border border-black rounded p-1 w-full sm:w-auto"
                        />
                        <div className="ml-2">
                            <button
                                onClick={handleExport}
                                className="px-4 py-2 bg-[#117DAE] text-white rounded-3xl text-sm hover:bg-[#0e72a0]"
                            >
                                <FileDownload style={{ fontSize: 25, marginRight: "2px" }} />
                                {downloadProgress > 0 ? "Downloading" : "Export Data "}
                            </button>
                        </div>
                    </div>
                </div>

                <Box className="py-4">
                    <div className="h-[60vh] overflow-y-auto">
                        <Table
                            className="min-w-750 bg-white"
                            aria-labelledby="tableTitle"
                            size="medium"
                        >
                            <TableHead className="bg-gray-800">
                                <TableRow className="border-b">
                                    {tableCellsData.map((cellData) => (
                                        <TableCell
                                            key={cellData.id}
                                            padding="normal"
                                            className="p-2"
                                            sx={{
                                                color: "white",
                                                fontWeight: 600,
                                                textAlign: "center",
                                            }}
                                        >
                                            {cellData.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={6} style={{ textAlign: "center" }}>
                                            Loading...
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    devices?.rows.map(
                                        (device: any) => (
                                            <TableRow key={device.id}>
                                                <TableCell
                                                    style={{ textAlign: "center", padding: "10px" }}
                                                >
                                                    {device?.lastResponseStatus[0]?.IMEI || "N/A"}
                                                </TableCell>

                                                <TableCell
                                                    style={{ textAlign: "center", padding: "10px" }}
                                                >
                                                    {device?.Device?.deviceName || "N/A"}
                                                </TableCell>
                                                <TableCell
                                                    style={{ textAlign: "center", padding: "10px" }}
                                                >
                                                    {device?.Device?.deviceAlias || "N/A"}
                                                </TableCell>



                                                <TableCell
                                                    className="text-center"
                                                    style={{ textAlign: "center" }}
                                                >
                                                    <span
                                                        className={`text-${device.status === "SUCCESS" ? "green-600" : "red-600"
                                                            } 
  
                              ${device.status === "SUCCESS"
                                                                ? "bg-green-200"
                                                                : "bg-red-100"
                                                            } 
  
                              rounded-md 
  
                              ${device.status === "SUCCESS"
                                                                ? "px-4 py-2"
                                                                : "px-3 py-2"
                                                            } `}
                                                    >
                                                        {device.status}
                                                    </span>
                                                </TableCell>
                                                <TableCell style={{ textAlign: "center", padding: "10px" }}>
                                                    {device?.Device?.lastDataPoint?.rssi || "N/A"}
                                                </TableCell>
                                                <TableCell style={{ textAlign: "center", padding: "10px" }}>
                                                    {device.updatedAt ? moment(device.updatedAt).utcOffset(5.5).format('YYYY-MM-DD HH:mm:ss') : "N/A"}
                                                </TableCell>


                                            </TableRow>
                                        )
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </div>

                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[]}
                        count={devices?.totalItems || 0}
                        rowsPerPage={10}
                        page={page}
                        onPageChange={handlePageChange}
                        className="bg-gray-100"
                        labelDisplayedRows={({ from, to, count }) => {
                            return `${from}-${to} of ${count}`;
                          }}
                    />
                </Box>
            </Paper>
        </Box>
    );
}

export default ScheduleResponse;
