import { useState } from "react";
import { InputLabel } from "@mui/material";
import { http } from "../../helper/http";
import csvtojson from "csvtojson";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import exportExcel from "../../utils/export/excel-export";

const baseUrl = process.env.REACT_APP_BASE_URL;

const CsvUpload = () => {
  const [csvData, setCsvData] = useState<any>([]);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { id } = useParams();

  const handleFileChange = async (event: any) => {
    try {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      const fileExtension = file.name.split('.').pop()?.toLowerCase();

      if (fileExtension === "csv") {
        reader.onload = async (e: any) => {
          try {
            const csvData = e.target.result;
            const jsonArray = await csvtojson().fromString(csvData);
            setCsvData(jsonArray);
          } catch (err) {
            toast.error("Error parsing CSV file");
          }
        };
        reader.readAsText(file);
      } else if (fileExtension === "xlsx" || fileExtension === "xls") {
        reader.onload = (e: any) => {
          try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);
            setCsvData(jsonData);
          } catch (err) {
            toast.error("Error parsing Excel file");
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        toast.error("Unsupported file format");
      }
    } catch (error) {
      toast.error("Error reading file");
    }
  };

  const handlePost = async () => {
    try {
      if (csvData.length > 0) {
        setIsUploading(true);
        setUploadProgress(10); // Initial progress

        let queryParams = `/csv-upload?deviceId=${id}`;
        await exportExcel(
          baseUrl + queryParams,
          (progress) => {
            setUploadProgress(progress);
          },
          "POST",
          csvData,
          "upload_stats.xlsx"
        );

        setUploadProgress(100);
        setTimeout(() => {
          setIsUploading(false);
          setUploadProgress(0);
        }, 1000);
      } else {
        toast.info("Please select a file to upload");
      }
    } catch (error) {
      toast.error("Error uploading data");
      setIsUploading(false);
    }
  };

  const handleGetXLSXFormat = async () => {
    try {
      const data = await http(`${baseUrl}/tag-groups?deviceId=${id}`, "GET");
      const columnNames: any = data;
      const worksheetData = [columnNames]; // First row is the column headers
      for (let i = 0; i < 2; i++) {
        const row = columnNames.map((column: any) => {
          if (column === "Firmware Version Id") {
            return "v1"; // Specific value for "Firmware Version Id"
          }
          if (column === "timeStamp") {
            return "YYYY-MM-DD HH:MM:SS";
          }
          if (column === "rssi") {
            return 20;
          }
          return Math.floor(Math.random() * 900) + 100;
        });
        worksheetData.push(row);
      }
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "data_format.csv");
    } catch (error) {
      console.log(error);
      toast.error("Unexpected error occurred");
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-lg border border-gray-200">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">
        CSV Upload
      </h1>
      <div className="mb-4">
        <InputLabel
          htmlFor="csv-file"
          className="block font-medium text-gray-700 mb-2"
        >
          Upload CSV File
        </InputLabel>
        <input
          type="file"
          id="csv-file"
          accept=".csv,.xlsx"
          onChange={handleFileChange}
          className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <p className="text-sm text-gray-600 mt-2">
          <strong>Note:</strong> The CSV file must contain a maximum of 500 rows.
        </p>
        <p className="text-sm text-gray-600 mt-1">
          <strong>Note:</strong> All column names are case-sensitive. The column names should match exactly as per the provided CSV/XLSX format.
        </p>
        <p className="text-sm text-gray-600 mt-1">
          <strong>Note:</strong> Always provide a unique Firmware Version ID when uploading a CSV to avoid any issues in case of a mishap.
        </p>
      </div>

      {isUploading && (
        <div className="mb-4">
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div
              className="bg-blue-600 h-2.5 rounded-full transition-all"
              style={{ width: `${uploadProgress}%` }}
            ></div>
          </div>
          <p className="text-center text-gray-600 text-sm mt-1">
            Uploading... {uploadProgress}%
          </p>
        </div>
      )}

      <div className="flex space-x-4">
        <button
          onClick={handlePost}
          disabled={isUploading}
          className={`flex-1 py-2 px-4 rounded-lg transition duration-300 ${
            isUploading
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-600 text-white hover:bg-blue-700"
          }`}
        >
          {isUploading ? "Uploading..." : "Upload CSV"}
        </button>
        <button
          onClick={handleGetXLSXFormat}
          className="flex-1 bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700 transition duration-300"
        >
          Get XLSX Format
        </button>
      </div>
    </div>
  );
};

export default CsvUpload;
