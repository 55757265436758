import React from "react";
import { Dialog, DialogContent, CircularProgress } from "@mui/material";

interface LoaderProps {
  openLoader: boolean;
  setOpenLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

const Loader4: React.FC<LoaderProps> = ({ openLoader, setOpenLoader }) => {
  return (
    <Dialog
      open={openLoader}
      onClose={(event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
          return;
        }
        setOpenLoader(false);
      }}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          textAlign: "center",
        }}
      >
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};

export default Loader4;
